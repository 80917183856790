import React, { useState, useRef, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types';
import { Container, Row, Col } from "react-bootstrap";

// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

import ReferEmployerForm from "@components/forms/refer-employer/refer-employer-form";
import ContactSidebar from "@components/modules/contact-sidebar/";

// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

const ReferEmployer = (props) => {

    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        }

        setValidated(true);
    };

    let formAnim = useRef(null);
    useEffect(() => {
        const tl = gsap.timeline({
            defaults: {
                duration: formAnim.duration,
                ease: animSetting.ease,
            },
            scrollTrigger: {
                trigger: formAnim,
                start: 'top 85%',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                ease: formAnim.ease
            }
        });
        tl.fromTo(formAnim, {
            opacity: 0,
            y: 70,
        },{
            opacity: 1,
            y: 0,
            duration: 1.5
        })
    }, [])

    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "young-woman.jpg" }) {
                childImageSharp {
                    fluid(quality: 80, maxWidth: 400) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `)
    const sidebarData = {
        hideModuleBg: true,
        sidebarVideo: {
            posterImage: data.file,
            videoask: true,
            videoId: "https://www.videoask.com/fiumu1gjs",
            videoTitle: "If you know someone who needs to fill PropTech or estate agent jobs in the UK, enter their details here."

        }
    }

    return (
        <section className={`section section-form${props.sectionClass}`}>
            <Container>
                <Row>
                    <Col xs={12}>
                        <h1 className={`section-title${props.titleClass}`}>{props.title}</h1>
                        <Row id="sidebar-end-scroll">
                            <Col xs={12} xl={8} ref={el => formAnim = el}>
                                <p className={props.introClass}>{props.intro}</p>
                                <ReferEmployerForm
                                    formClass={props.formClass}
                                    formSubmitContainerClass={props.formSubmitContainerClass}
                                    formSubmitClass={props.formSubmitClass}
                                    formTermsClass={props.formTermsClass}
                                    formRequiredClass={props.formRequiredClass}
                                />
                            </Col>
                            <ContactSidebar
                                sidebarData={sidebarData}
                            />
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

// Check all the prop types
ReferEmployer.propTypes = {
    sectionClass: PropTypes.string,
    titleClass: PropTypes.string,
    title: PropTypes.string,
    introClass: PropTypes.string,
    intro: PropTypes.string,
    formClass: PropTypes.string
};

// Specifies the default values for props:
ReferEmployer.defaultProps = {
    sectionClass: ' pt-34 pb-36 pt-md-52 pb-md-60 pt-xl-72 pb-xl-94',
    titleClass: ' col-xs-12 mb-14 mb-md-27 mb-xl-31',
    title: 'Register as an Employer',
    introClass: 'mb-23 mb-md-48',
    intro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras mattis dictum purus, et condimentum arcu dapibus in lipsum dolor.',
    formClass: '',
    sidebarData: [
        {
            sidebarVideo: {
                videoask: true,
            },
            hideModuleBg: true
        }
    ]
};

export default ReferEmployer